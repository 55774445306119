// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { Loader, SearchBarWithSuggestions } from "components/elements";
import { Info } from "components/feedback";
import { trans } from "src/translations";
import { classes } from "utils/classes";
import { useModal } from "utils/hooks";

import { getSuggestions } from "./_utils";
import { ActionModal } from "./ActionModal/ActionModal";
import { FiltersList } from "./FiltersList/FiltersList";
import { useSearchBar } from "./hooks/useSearchBar";
import { SearchBarWithFiltersPropTypes } from "./propTypes";
import styles from "./SearchBarWithFilters.scss";
import { withSuggestions } from "./withSuggestions";

/**
 * `<Searchbar>` that also allows data filtering. Insert `/` to access the filter
 * system. `displayName` prop for each value cannot contain spaces, use "\_" instead.
 *
 * This component is very complex, not all propTypes are listed in this guide.
 * More exhaustive explanation can be seen in the actual code.
 *
 */

export const renderDropdownContent = (
  backendSuggestions,
  availableFiltersError
) => {
  if (availableFiltersError || backendSuggestions.suggestionsError) {
    const error = availableFiltersError
      ? [styles.availableFiltersError, trans.SEARCH_BAR__FILTERS_ERROR()]
      : [styles.suggestionsError, trans.SEARCH_BAR__SUGGESTIONS_ERROR()];

    return (
      <Info type="error" className={error[0]}>
        {error[1]}
      </Info>
    );
  }

  if (backendSuggestions.isLoadingSuggestions) {
    return (
      <div className={styles.suggestionsLoader}>
        <Loader message={`${trans.LOADING()}...`} />
      </div>
    );
  }

  return null;
};

const SearchBarWithFiltersComponent = ({
  backendSuggestions,
  filtersDataChoices = { sections: [] },
  availableFiltersError,
  onSearch,
  onChange,
  look = "for-gray-bg",
  placeholder = trans.SEARCH_AND_FILTER(),
  // @todo LVCS-2784 - Those three props were used by Device Inventory. We need to decide whether we want to keep this feature.
  actionTrigger,
  hasExcludeOption,
  actionModal,
}) => {
  const {
    searchValue,
    onSearchBarChangeHandler,
    onSearchHandler,
    includeFilters,
    excludeFilters,
    removeFilter,
  } = useSearchBar({
    availableFilters: filtersDataChoices,
    backendSuggestions,
    onSearch,
    onChange,
  });
  const [isOpen, handleCloseModal, handleOpenModal] = useModal();

  const { suggestions, optionComponent, filterBeforeDisplay } = getSuggestions(
    searchValue,
    filtersDataChoices,
    backendSuggestions
  );

  const calcClassName = classes(styles.SearchBarWithFilters, styles[look]);

  return (
    <div className={calcClassName}>
      <SearchBarWithSuggestions
        optionComponent={optionComponent}
        value={searchValue}
        suggestionsInSections={suggestions}
        onChange={onSearchBarChangeHandler}
        placeholder={placeholder}
        onSearch={onSearchHandler}
        look={look}
        dropdownContent={renderDropdownContent(
          backendSuggestions,
          availableFiltersError
        )}
        filterBeforeDisplay={filterBeforeDisplay}
      />
      <FiltersList
        filters={includeFilters}
        exclusionFilters={excludeFilters}
        hasPossibleFilters={filtersDataChoices.sections.length > 0}
        onFilterClick={removeFilter}
        actionTrigger={actionTrigger && actionTrigger(handleOpenModal)}
        hasExcludeOption={hasExcludeOption}
      />
      {actionModal && (
        <ActionModal
          actionModal={actionModal}
          searchValue={searchValue}
          handleSearch={onSearchHandler}
          isOpen={isOpen}
          handleCloseModal={handleCloseModal}
        />
      )}
    </div>
  );
};
SearchBarWithFiltersComponent.propTypes = {
  ...SearchBarWithFiltersPropTypes,

  // from @withSuggestions
  backendSuggestions: PropTypes.shape({
    refreshSuggestions: PropTypes.func.isRequired,
    abortFetchingSuggestions: PropTypes.func.isRequired,
    hasBackendValueSuggestions: PropTypes.func.isRequired,
    suggestions: PropTypes.array,
    isLoadingSuggestions: PropTypes.bool.isRequired,
    suggestionsError: PropTypes.object,
  }).isRequired,
};

export const SearchBarWithFilters = withSuggestions(
  SearchBarWithFiltersComponent
);
