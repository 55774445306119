// Copyright © 2024 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { Label } from "components/form";
import { Link } from "components/typography/Link/Link";

import styles from "./SnapURIs.scss";

const PREVIEW_URIs = [
  { label: "Snap 3 - Legacy version for TV", query: "?layout=snap3" },
  { label: "Snap 4 - version for TV", query: "?layout=snap4&nav=tv" },
  {
    label: "Snap 4 - for automotive (requires touch navigation enabled)",
    query: "?layout=snap4",
  },
];

function getSnapURI(url, query, preview) {
  const httpLength = 5;
  let end;
  let version = "";
  if (preview) {
    end = url.lastIndexOf(":") > httpLength ? url.lastIndexOf(":") : url.length;
    version = ":latest";
  } else {
    end = url.charAt(url.length - 1) === "/" ? url.length - 1 : url.length;
  }
  return url.substr(0, end) + version + query;
}

export function SnapURIs({ snapURL, preview = false }) {
  return snapURL ? (
    <>
      {PREVIEW_URIs.map(({ label, query }, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={`uri_${idx}`} className={styles.uri}>
          <Label text={label} />
          <Link href={getSnapURI(snapURL, query, preview)}>
            {getSnapURI(snapURL, query, preview)}
          </Link>
        </div>
      ))}
    </>
  ) : null;
}

SnapURIs.propTypes = {
  snapURL: PropTypes.string,
  preview: PropTypes.bool,
};
